<script setup lang="ts">
import { onServerPrefetch, useSSRContext } from 'vue';

onServerPrefetch(async () => {
  const ctx = useSSRContext() as Record<string, any>;
  ctx.httpStatusCode = 404;
});
</script>

<template>
  <main class="grid min-h-full place-items-center bg-white px-24 py-96 lg:px-32 sm:py-128">
    <div class="text-center">
      <p class="text-28 text-brand-primary font-semibold">404</p>
      <h1 class="mt-16 text-28 text-gray-900 font-bold tracking-tight sm:text-48">{{ $t('404.notFound') }}</h1>
      <div class="mt-40 flex items-center justify-center gap-x-24">
        <RouterLink to="/" class="rounded-4px bg-brand-primary px-14 py-10 text-14 text-white decoration-none">{{ $t('404.goBack') }}</RouterLink>
      </div>
    </div>
  </main>
</template>
